const contextSelector = (function () {
    'use strict';
    const { execProc, getDataFromView } = __dataAccess;
    const $d = document;
    const isNewTech = __navBarHelpers.isNewTech();
    const t = __navBarHelpers.translate();
    const events = { "onContextChanged": [] };
    let state = {
        context: __navBarData?.user?.contextId,
        contextObject: {
            ID: __navBarData?.orgUnit?.id,
            name: __navBarData?.orgUnit?.name,
            AccessIdPath: __navBarData?.orgUnit?.accessIdPath,
            IdPath: __navBarData?.orgUnit?.idPath,
            idPath: __navBarData?.orgUnit?.idPath,
            OrgUnitName: __navBarData?.orgUnit?.orgUnit,
        },
        updateBreadCrumb: true,
    };

    function updateContext(id, opts, userTriggered, domain, callback) {
        id = Number(id);
        if (isNaN(id) || !id) {
            return;
        }
        if (!isNewTech) {
            window.$(function () {
                window.af.userSession.contextId = id;
            });
        }

        const params = { TriggeredByUser: userTriggered ? true : false, ...opts, ID: id };
        setContext(id, params, userTriggered, domain, callback);

        getDataFromView({
            viewName: "sviw_System_OrgUnitsPersonsCurrentUnitForToolbar",
            whereClause: `OrgUnit_ID = ${id}`,
            maxRecords: 1,
            fields: [{ name: "IdPath" }, { name: "OrgUnit" }, { name: "OrgUnitName" }]
        }).then(data => {
            if (!data) {
                return;
            }
            params.IdPath = data.IdPath;
            params.idPath = data.IdPath;
            params.OrgUnit = data.OrgUnit;
            params.OrgUnitName = data.OrgUnitName;
            fireEvent("onContextChanged", params);
        });

        if (!opts || !opts.preventSaving) {
            updateQueryString(id);
            execProc({ procName: "sstp_System_OrgUnitUpdateCurrent", data: { OrgUnit_ID: id } });
        }
    }

    function refreshDataSource(ds, fieldName) {
        fieldName = fieldName || "OrgUnit_ID";

        attachEvent("onContextChanged", pCtx => {
            const newWhereClause = `${fieldName} = ${pCtx.ID}`;
            if (isNewTech) {
                ds.recordSource.whereClause = newWhereClause;
                ds.load();
            } else {
                ds.setParameter("whereClause", newWhereClause);
                ds.refreshDataSource();
            }
        });
    }

    function setContext(context, opts, userTrigged, _domain, callback) {
        context = Number(context);
        state.context = context;
        state.contextObject = Object.assign({ ID: context }, opts);

        if (!state.context) {
            return;
        }

        getDataFromView({
            viewName: "sviw_System_OrgUnitsPersonsCurrentUnitForToolbar",
            maxRecords: 1,
            whereClause: `OrgUnit_ID = ${context}`,
            fields: [{ name: "OrgUnitName" }, { name: "OrgUnit" }, { name: "IdPath" }, { name: "AccessIdPath" }, { name: "OrgUnitNameAndTitle" }]
        }).then(data => {
            if (!data) {
                if (callback) {
                    callback(state.contextObject);
                }
                return;
            }
            if (userTrigged) {
                if ($d.querySelector(".context-name")) {
                    $d.querySelector(".context-name").textContent = data.OrgUnitName;
                }
                if ($d.querySelector(".context-name-lg")) {
                    $d.querySelector(".context-name-lg").textContent = data.OrgUnitName;
                }
                if ($d.querySelector("#context-menu .dropdown-usercontext span")) {
                    $d.querySelector("#context-menu .dropdown-usercontext span").textContent = data.OrgUnit;
                }
                if (state.updateBreadCrumb) {
                    if ($d.querySelector(".home-context-name")) {
                        $d.querySelector(".home-context-name").textContent = data.OrgUnitName;
                    }
                    if ($d.querySelector(".home-context-name-lg")) {
                        $d.querySelector(".home-context-name-lg").textContent = data.OrgUnit;
                    }
                }
            } else if (opts?.preventSaving && state.updateBreadCrumb) {
                if ($d.querySelector(".home-context-name")) {
                    $d.querySelector(".home-context-name").textContent = data.OrgUnitName;
                }
                if ($d.querySelector(".home-context-name-lg")) {
                    $d.querySelector(".home-context-name-lg").textContent = data.OrgUnit;
                }
            }

            state.contextObject = {
                ID: context,
                name: data.OrgUnitNameAndTitle,
                AccessIdPath: data.AccessIdPath,
                IdPath: data.IdPath,
                idPath: data.IdPath,
                OrgUnitName: data.OrgUnitName,
            };

            if (callback) {
                callback(state.contextObject);
            }
        });
    }

    function getSessionContext() {
        return state.context;
    }

    function getSessionContextObject() {
        return state.contextObject;
    }

    function setNavigateTo(obj) {
        if (obj.domainOrgUnit_ID && obj.domainOrgUnit) {
            if ($d.querySelector("#context-menu .dropdown-domain span")) {
                $d.querySelector("#context-menu .dropdown-domain span").textContent = obj.domainOrgUnit;
            }
            $d.querySelector("#context-menu .dropdown-domain")?.setAttribute("href", $d.querySelector("#context-menu .dropdown-orgunit").getAttribute("href").split("?")[0] + "?Context=" + obj.domainOrgUnit_ID);
            $d.querySelector("#context-menu .dropdown-domain")?.classList?.remove("d-none");
            $d.querySelector('[data-dropdown-target="#context-menu"]')?.setAttribute("title", t("Current Context: ") + obj.domainOrgUnit + " " + t("Domain / Project"));
        } else {
            $d.querySelector('[data-dropdown-target="#context-menu"]')?.setAttribute("title", t("Current Context: ") + obj.orgUnit + " " + t("(Org Unit From Current Record)"));
        }
        if (obj.orgUnit_ID && obj.orgUnit) {
            if ($d.querySelector("#context-menu .dropdown-orgunit span")) {
                $d.querySelector("#context-menu .dropdown-orgunit span").textContent = obj.orgUnit;
            }
            $d.querySelector("#context-menu .dropdown-orgunit")?.setAttribute("href", $d.querySelector("#context-menu .dropdown-orgunit").getAttribute("href").split("?")[0] + "?Context=" + obj.orgUnit_ID);
            $d.querySelector("#context-menu .dropdown-orgunit")?.classList?.remove("d-none");
        }
        state.updateBreadCrumb = false;
    }

    function replaceQueryParam(param, newVal, search) {
        search = search || location.search;
        const regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
        const query = search.replace(regex, "$1").replace(/&$/, '');
        return (query.length > 2 ? query + "&" : "?") + (newVal ? param + "=" + newVal : '');
    }

    function updateQueryString(orgUnitId) {
        const url = window.location.pathname.replace(/\/\/+/, '/') + replaceQueryParam("Context", orgUnitId);
        history.replaceState({ ID: orgUnitId }, '', url + window.location.hash);
    }

    function attachEvent(eventName, handlerFunc) {
        if (!events.hasOwnProperty(eventName)) {
            throw new Error(`The event '${eventName}' does not exist`);
        }
        events[eventName].push(handlerFunc);
    }

    function detachEvent(eventName, handlerFunc) {
        if (!events.hasOwnProperty(eventName)) {
            throw new Error(`The event '${eventName}' does not exist`);
        }
        events[eventName] = events[eventName].filter(f => f !== handlerFunc);
    }

    function fireEvent(eventName, data) {
        events[eventName]?.forEach(handlerFunc => handlerFunc(data));
    }

    return { attachEvent, detachEvent, updateContext, refreshDataSource, getSessionContext, getSessionContextObject, setNavigateTo, setContext };
})();

window["contextSelector"] = contextSelector;

// This is a wrapper built around contextSelector, for backwards compatability purposes
const orgunit_lookup = (() => {
    function attachEvent(eventName, callbackFunc) {
        if (eventName !== "onOrgunitChanged") {
            throw new Error(`Event '${eventName}' is not supported anymore`);
        }
        contextSelector.attachEvent("onContextChanged", (obj) => {
            callbackFunc({
                orgUnit_ID: obj.ID,
                orgUnitName: obj.OrgUnitName,
                idPath: obj.idPath || obj.IdPath,
                contextSelector: null /* ?? */,
            });
        });
    }

    function detachEvent(eventName, callbackFunc) {
        if (eventName !== "onOrgunitChanged") {
            throw new Error(`Event '${eventName}' is not supported anymore`);
        }
        contextSelector.detachEvent("onContextChanged", callbackFunc);
    }

    function idPath() {
        return contextSelector.getSessionContextObject().idPath || contextSelector.getSessionContextObject().IdPath;
    }

    function orgUnitID() {
        return contextSelector.getSessionContext();
    }
    function changeOrgunit(orgunit_ID, _orgunitName, _idPath, preventSaving) {
        contextSelector.updateContext(orgunit_ID, { preventSaving });
    }

    function currentContext() {
        return contextSelector.getSessionContext();
    }

    function getSessionContext() {
        return contextSelector.getSessionContext();
    }

    function addAutoContextUpdatingForID(dataObject) {
        contextSelector.refreshDataSource(dataObject, "ID");
    }

    function addAutoContextUpdatingForIdPath() {
        // Deprecated. Cannot find any uses of this in code-search.
        throw new Error(`The ${addAutoContextUpdatingForIdPath.name} method is deprecated. Please switch to using contextSelector instead.`);
    }

    function updateQueryString(orgUnitId) {
        // Deprecated. Cannot find any uses of this in code-search.
        throw new Error(`The ${updateQueryString.name} method is deprecated. Please switch to using contextSelector instead.`);
    }

    function setOrgUnitParams(pOrgUnit_ID, pOrgunitName, pIdPath) {
        // Deprecated. Cannot find any uses of this in code-search.
        throw new Error(`The ${setOrgUnitParams.name} method is deprecated. Please switch to using contextSelector instead.`);
    }

    return { attachEvent, detachEvent, idPath, setOrgUnitParams, orgUnitID, updateQueryString, changeOrgunit, currentContext, getSessionContext, addAutoContextUpdatingForID, addAutoContextUpdatingForIdPath };
})();

window["orgunit_lookup"] = orgunit_lookup;